.c-person-list {
	display: -webkit-box;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	list-style: none;
	margin: 0 auto;
	padding: 0;

	& .person-image {
		& > picture {
			display: block;
			width: 100%;

			& > img {
				display: block;
				width: 100%;
			}
		}
	}

	& .person-info {
		& > h3 {
			margin-bottom: 0;
		}

		& > h6 {
			color: var(--gray-5);
			margin: 0.5rem 0;
		}

		& .social-links {
			display: -webkit-box;
			display: flex;
			list-style: none;
			padding: 0;

			& > li {
				margin-right: 15px;

				& img {
					display: block;
				}
			}
		}
	}
}
