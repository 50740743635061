.c-speakers-section {
	& .speakers-header {
		color: var(--gray-4);
		text-transform: uppercase;
	}

	& .speakers {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		list-style: none;
		margin: 0;
		padding: 0;

		@media (max-width: 600px) {
			flex-direction: column;
		}

		& .speaker {
			display: flex;
			margin-bottom: 40px;
			margin-right: 3%;
			width: 47%;

			&:nth-child(2n) {
				margin-right: 0;
			}

			@media (max-width: 600px) {
				margin-right: 0;
				width: 100%;
			}

			&.single {
				margin-right: 0;
				width: 100%;
			}

			& .person-image {
				background: var(--gray-5);
				border-radius: 50%;
				flex-shrink: 0;
				height: 85px;
				margin-bottom: 10px;
				overflow: hidden;
				width: 85px;

				& img {
					width: 100%;
				}

				& .placeholder {
					align-items: center;
					background-color: var(--gray-2);
					color: white;
					display: flex;
					height: 100%;
					justify-content: center;
					width: 100%;

					& .initial {
						font-size: 40px;
					}
				}
			}

			& .person-info {
				display: flex;
				flex-direction: column;
				margin-left: 15px;

				/* Dupe and override our g-type-display-5 styles */
				& .name {
					font-family: 'gilmer-web', 'Gilmer', Geneva, Tahoma, Helvetica,
						Verdana, sans-serif;
					font-size: 1rem;
					font-weight: 700;
					line-height: 1.556em;
					margin-bottom: 5px;
				}

				& .job {
					line-height: 1.2;
				}

				& ul.social-links {
					display: flex;
					justify-content: flex-start;
					list-style: none;
					margin-top: 15px;
					padding: 0;

					& li {
						margin-right: 15px;
					}
				}
			}
		}
	}
}
