.g-demo-request {
	background: var(--brand);
	color: var(--white);
	overflow: hidden;
	padding: 100px 0;
	position: relative;

	/* Debt: implement light theme for section-header? 
   task: https://app.asana.com/0/1100423001970639/1201097676089808/f */
	& .g-section-header > div {
		color: var(--white);
	}

	& .g-demo-request-form {
		display: flex;
		justify-content: center;
		margin-top: 32px;

		@media (max-width: 650px) {
			flex-direction: column;
		}

		& .g-text-input {
			border: none;
			margin-right: 20px;
			width: 300px;

			@media (max-width: 650px) {
				margin-bottom: 20px;
				margin-right: 0;
				width: 100%;
			}
		}
	}
}
