.g-packages {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;

	@media (max-width: 925px) {
		flex-direction: column;
	}

	@media (max-width: 600px) {
		margin-right: 0;
	}

	& > li {
		margin-right: 3.5%;
		padding: 50px 30px 30px 30px;
		width: 31%;

		&:last-child {
			margin-bottom: 0;
			margin-right: 0;
		}

		@media (max-width: 925px) {
			margin-bottom: 25px;
			margin-right: 0;
			width: 100%;
		}

		& .top {
			align-items: center;
			display: flex;
			flex-direction: column;
			text-align: center;

			& h2 {
				align-items: center;
				display: flex;
				font-size: 2em;
				margin: 0;

				& .tag {
					border-radius: 2px;
					font-size: 0.4em;
					line-height: 1em;
					margin-left: 10px;
					padding: 5px;
					text-transform: uppercase;
				}
			}

			& svg {
				display: block;
				height: 50px;
				margin: 40px 0;
				max-width: 100%;
			}

			& strong {
				max-width: 100%;
				min-height: 80px;
				@media (max-width: 925px) {
					min-height: initial;
				}
			}

			& .g-btn {
				margin-bottom: 50px;
				margin-top: 20px;
				width: 100%;

				@media (max-width: 925px) {
					width: 250px;
				}
			}

			& .features-plus {
				align-items: center;
				border-bottom: 1px solid;
				display: flex;
				font-size: 0.9em;
				font-weight: var(--font-weight-bold);
				margin-top: 0;
				padding-bottom: 10px;
				text-align: left;
				width: 100%;

				@media (max-width: 925px) {
					justify-content: center;
				}
			}
		}

		&:not(.enterprise) {
			background: white;
			border: 1px solid var(--gray-5);
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

			& > ul li a {
				border-bottom: 1px dashed var(--gray-3);
			}

			& .g-tooltip svg g {
				fill: var(--gray-3);
			}
		}

		&.enterprise {
			/* TODO: deal with this */
			--input-background-dark: #1b212d;

			background: var(--input-background-dark);
			color: var(--white);

			& > ul li a {
				border-bottom: 1px dashed var(--gray-2);
			}

			& .g-tooltip svg g {
				fill: var(--gray-2);
			}
		}

		& > ul {
			list-style: none;
			margin: 0;
			padding: 0;
			text-align: left;

			@media (max-width: 925px) {
				text-align: center;
			}

			& li {
				font-size: 0.95em;
				margin-bottom: 20px;

				&:last-child {
					margin-bottom: 0;
				}

				& a {
					color: inherit;
					padding-bottom: 1px;
				}

				& span {
					display: inline-flex;
					margin-left: 8px;
					vertical-align: middle;
				}

				&.g-tooltip svg {
					height: 14px;
					margin-left: 5px;
					vertical-align: middle;
					width: 14px;
				}
			}
		}
	}
}
