.legacy-resource-content {
	& .category {
		align-items: center;
		display: flex;

		& img {
			max-width: 20px;
		}

		& span {
			color: var(--gray-3);
			font-size: 0.75em;
			font-weight: 600;
			margin-left: 10px;
			text-transform: uppercase;
		}
	}

	& > h1.title {
		letter-spacing: -0.6px;
		line-height: 1.1em;
		margin-bottom: 24px;
		margin-top: 24px;

		@media (max-width: 850px) {
			font-size: 3rem;
		}
	}

	& .label {
		color: var(--gray-3);
		font-size: 0.75em;
		font-weight: 600;
		letter-spacing: 0.2px;
		line-height: 1.15;
		margin: 0 0 16px;
		text-transform: uppercase;
	}

	& > h3 {
		font-weight: 300;
		margin-top: 1.33em;
	}

	& h1,
	& h2 {
		margin: 1em 0 0.5em 0;
	}

	& h5 {
		margin: 1em 0;
	}

	& hr {
		margin: 48px 0;

		&:last-child {
			display: none;
		}
	}

	& blockquote {
		border-left: 8px solid var(--brand);
		font-size: 1.5em;
		line-height: 1.625;
		margin-left: 0;
		padding-left: 2em;
	}

	& .modular-content-image-section {
		margin: 0;

		& img {
			margin: 1em 0;
			max-width: 100%;
		}
	}

	& section.text {
		font-size: var(--font-size-default);

		/*
    Note: we've moved @hashicorp/react-button to CSS modules.
    These older, fixed-variant g-btn styles have been localized
    from the previous button component.
    
    This has been done to support some occasional legacy content cases
    where we chose to support authors in DatoCMS using <a className="g-btn" />
    to render a button. Here's one example:
    url: https://www.hashicorp.com/resources/service-mesh-microservices-networking
    Dato: https://hashicorp.admin.datocms.com/editor/item_types/700893/items/31694280/edit 
    */
		& .g-btn {
			align-self: center;
			appearance: none;
			background-color: var(--brand);
			border: 0;
			border-radius: 2px;
			border-style: solid;
			box-sizing: border-box;
			color: var(--white);
			cursor: pointer;
			display: inline-block;
			font-family: var(--font-body);
			font-size: 1rem;
			font-weight: var(--font-weight-bold);
			line-height: 1.375rem;
			outline: 0;
			padding: 13px 24px;
			position: relative;
			text-align: center;
			transition: transform 0.25s ease;

			&::before {
				background: rgba(255, 255, 255, 0.15);
				bottom: 0;
				content: '';
				left: 0;
				opacity: 0;
				position: absolute;
				right: 0;
				top: 0;
				transition: opacity 0.25s ease;
			}

			&:hover {
				text-decoration: none;
				transform: translateY(-1px);

				&::before {
					opacity: 1;
				}
			}
		}
	}

	& .c-speakers-section {
		& h3 {
			color: var(--black);
			font-size: 1.5em;
			margin: 1em 0;
			text-transform: none;
		}

		& .speaker {
			margin-bottom: 30px;
			width: 100%;
		}

		& .person-image {
			height: 105px;
			margin-bottom: 0;
			width: 105px;

			& img {
				width: 100%;
			}
		}

		& .person-info {
			padding-top: 1em;
		}
	}

	& .full-width img {
		width: 100%;
	}

	& .image-center,
	& .image-right {
		display: flex;
		flex-direction: column;
	}

	& .image-center {
		align-items: center;
	}

	& .image-right {
		align-items: flex-end;
	}

	& .g-type-caption {
		font-size: 0.85em;
		width: 100%;
	}

	& .podcast {
		& .soundcloud-container {
			padding-bottom: 1em;
		}

		& .podcast-subscribe-links {
			margin: 1em 0;

			& .btn-container {
				display: flex;

				@media (max-width: 650px) {
					flex-direction: column;
				}

				& > * + * {
					margin-left: 32px;

					@media (max-width: 650px) {
						margin-left: 0;
						margin-top: 24px;
					}
				}
			}
		}
	}

	& .product-link {
		color: inherit;
		font-weight: 600;

		&::after {
			content: '\203A';
			font-size: 1.8rem;
			padding-left: 1rem;
		}
	}

	& .consul {
		background: var(--consul);
	}

	& .vault {
		background: var(--vault);
	}

	& .terraform {
		background: var(--terraform);
	}

	& .nomad {
		background: var(--nomad);
	}

	& p > code {
		background-color: #f9f2f4;
		color: #c7254e;
	}
}
