.sales-form-page {
	background: var(--white);
	color: var(--black);

	& .g-section-header {
		margin: 0 auto;
		max-width: 784px;
		text-align: center;

		& h1 {
			margin: 0;
		}
	}

	& .g-grid-container {
		display: flex;
		margin: 100px auto;
	}

	& .left {
		margin-right: 10%;
		width: 60%;

		@media (max-width: 900px) {
			margin-right: 0;
			width: 100%;
		}

		& .g-section-header {
			margin-bottom: 25px;
			text-align: left;
		}

		& form {
			display: flex;
			flex-direction: column;
			width: 60%;

			@media (max-width: 600px) {
				margin-right: 0;
				width: 100%;
			}

			& .hp-email-address {
				display: none;
			}

			& .g-btn {
				align-self: flex-start;
				margin-top: 24px;
			}
		}
	}

	& .right {
		width: 30%;

		@media (max-width: 900px) {
			display: none;
		}

		& h6 {
			color: var(--gray-4);
			font-size: 0.75em;
			margin-top: 15px;
			text-align: center;
			text-transform: uppercase;
		}

		& ul {
			list-style: none;
		}

		& .g-logo-grid {
			margin: 0 auto;

			& li {
				height: 80px;
				opacity: 0.6;
				width: calc(50% - 30px);

				& > div {
					border: none;

					& img {
						object-fit: contain;
						width: 70%;
					}
				}
			}
		}
	}
}
