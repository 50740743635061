#p-sentinel {
	& .g-hero > picture > img {
		bottom: unset;
		top: 0;
		width: 100%;
	}

	& .g-section-header {
		margin-bottom: 100px;
	}

	/* Callouts - What is sentinel? */
	& #callouts {
		background-color: var(--black);
		border-top: 1px solid var(--gray-1);
		color: var(--white);
		padding-bottom: 48px;
		padding-top: 48px;

		@media (min-width: 768px) {
			padding-bottom: 72px;
			padding-top: 72px;
		}

		@media (min-width: 1120px) {
			padding-bottom: 100px;
			padding-top: 100px;
		}
	}

	& #product-suite {
		padding-bottom: 64px;
		padding-top: 64px;

		@media (min-width: 768px) {
			padding-bottom: 96px;
			padding-top: 96px;
		}

		@media (min-width: 1120px) {
			padding-bottom: 120px;
			padding-top: 120px;
		}

		& .product-tabs {
			display: grid;
			grid-gap: 24px;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr 1fr;
			margin: 48px auto;
			padding: 0;

			@media (min-width: 768px) {
				margin: 64px auto;
			}

			@media (min-width: 1280px) {
				grid-gap: 32px;
				grid-template-columns: repeat(4, 1fr);
				grid-template-rows: unset;
			}

			& > li {
				border: 1px solid var(--gray-4);
				border-radius: 2px;
				box-shadow: 0 0 0 rgba(0, 0, 0, 0);
				display: flex;
				flex: 1;
				flex-direction: column;
				justify-content: center;

				/* margin-right: 16px; */
				transition: box-shadow 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);

				&:hover {
					box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
					cursor: pointer;

					@media (min-width: 320px) {
						& a .chevron {
							display: block;
							height: 24px;
							width: 24px;
						}
					}
				}

				&:last-child {
					margin-right: 0;
				}

				&.product-logo:hover {
					border-color: var(--brand);
					color: var(--gray-4);
				}
			}

			& a {
				align-items: center;
				color: inherit;
				display: flex;
				flex-direction: column;
				height: 56px;
				justify-content: center;
				min-width: 100%;
				padding: 12px;
				position: relative;
				width: max-content;

				@media (width >= 320px) {
					height: 150px;
				}

				& .chevron {
					bottom: 10px;
					display: none;
					position: absolute;
				}

				& img {
					max-width: 100%;

					@media (width >= 320px) {
						max-width: 80%;
					}

					&.enterprise-logo {
						display: none;
						height: 40px;

						@media (width >= 700px) {
							display: block;
						}
					}

					&.color-logo {
						display: block;
						height: 64px;
						width: 60px;

						@media (width >= 700px) {
							display: none;
						}
					}
				}
			}
		}

		& .products-wrap {
			& .code-sample {
				border-top-color: var(--brand);
				& li {
					color: var(--gray-2);
				}

				& .code-sample .header {
					background-color: var(--brand);
					border-color: var(--brand);
					color: var(--brand-text-on-primary);
				}
			}

			& section {
				border-top-style: solid;
				border-top-width: 8px;
				margin: 48px 0;
				padding-bottom: 48px;
				padding-top: 48px;

				@media (min-width: 320px) {
					padding-bottom: 64px;
					padding-top: 64px;
				}

				@media (min-width: 768px) {
					border-bottom-width: 1px;
					border-color: var(--gray-4);
					border-left-width: 1px;
					border-right-width: 1px;
					border-style: solid;
				}

				& > .header {
					display: flex;
					flex-direction: column;

					@media (min-width: 360px) {
						align-items: center;
						flex-direction: row;
						justify-content: center;
					}

					& .enterprise-logo {
						max-width: 280px;

						@media (min-width: 360px) {
							border-right: 1px solid var(--gray-5);
							padding-right: 24px;
							width: auto;
						}
					}

					& p {
						line-height: 1.6;
						margin: 30px 0 0 0;
						text-align: center;

						@media (min-width: 360px) {
							margin-top: 0;
							padding-left: 24px;
							text-align: left;
						}

						@media (min-width: 1120px) {
							font-size: 1.5em;
							line-height: 1.5;
							margin: 20px 0;
						}
					}
				}
			}

			& ul.checklist {
				display: flex;
				flex-direction: column;
				list-style: none;
				margin: 48px 0;
				padding: 0;

				@media (min-width: 1120px) {
					flex-direction: row;
					justify-content: center;
					margin: 64px 0;
				}

				& li {
					margin-bottom: 16px;
					padding-left: 30px;
					position: relative;

					&:last-child {
						margin-bottom: 0;
						padding-right: 0;
					}

					@media (min-width: 1120px) {
						margin-bottom: 0;
						max-width: 50%;
						padding-right: 30px;
					}
				}

				& svg {
					left: 0;
					position: absolute;
					top: 5px;
					width: 16px;
				}
			}

			& .button-container {
				margin: 32px auto 0;
				text-align: center;
				@media (min-width: 768px) {
					margin-top: 48px;
				}

				@media (min-width: 1120px) {
					margin-top: 64px;
				}
			}
		}
	}

	/* Hero - Policy as code is the next phase... */
	& .policy-as-code {
		border-bottom: 1px solid var(--gray-1);
		border-top: 1px solid var(--gray-1);

		& .headline {
			max-width: 900px;
		}
		& h3 {
			font-size: 1.2rem;
			line-height: 2rem;
		}
	}
}
