/* https://app.asana.com/0/346384260719996/1141408746824009
We'll be swapping out many of these styles once we switch to the CodeBlock component */
.g-code-switcher {
	display: flex;
	flex-direction: column;

	@media (min-width: 1120px) {
		flex-direction: row;
	}

	& ul {
		list-style: none;
		margin: 0 0 32px;
		padding: 0;

		@media (min-width: 1120px) {
			margin: 32px 32px 0 0;
			max-width: 375px;
		}

		& li {
			border-bottom: 1px solid var(--gray-6);
			cursor: pointer;
			font-size: 0.875rem;
			padding: 16px 32px;

			&:hover,
			&.active {
				background-color: var(--gray-6);
			}

			&:last-child {
				border-bottom: 0;
			}

			@media (min-width: 1120px) {
				font-size: 1rem;
			}
		}
	}

	& .code-sample {
		@media (min-width: 1120px) {
			flex: 1;
		}

		& .header {
			font-size: 0.75rem;
			font-weight: 600;
			letter-spacing: 0.5px;
			padding-bottom: 10px;
			padding-top: 10px;
			text-align: left;
		}
	}
}
