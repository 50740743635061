.g-jump-nav {
	align-self: flex-start;
	flex-shrink: 0;
	position: sticky;
	top: 0;
	width: 226px !important; /* stickyfill wrongly tries to change this value */

	@media (max-width: 767px) {
		display: none;
	}

	& ul {
		font-size: 0.875rem;
		font-weight: var(--font-weight-bold);
		line-height: 1.71;
		list-style: none;
		margin: 0;

		@media (max-width: 1119px) {
			padding-left: 58px;
		}
	}

	& li {
		margin: 12px 0;
	}

	& a {
		color: var(--black);
		opacity: 0.6;
		position: relative;

		&::before {
			background: url('/img/icons/arrow-right-blue.svg') no-repeat;
			background-size: 100%;
			content: '';
			display: block;
			height: 16px;
			left: -26px;
			opacity: 0;
			position: absolute;
			top: 2px;
			transition: margin 0.3s linear;
			width: 16px;
		}

		&:hover,
		&.active {
			opacity: 1;
		}

		&.active::before {
			margin-left: 3px;
			opacity: 1;
		}
	}
}
