.c-blog-hero {
	padding-bottom: 166px;
	padding-top: 88px;

	@media (max-width: 1120px) {
		width: 100%;
	}

	@media (max-width: 767px) {
		padding: 48px 0;
	}

	& .hero-container {
		width: 50%;
		@media (max-width: 767px) {
			margin-bottom: 90px;
		}

		@media (max-width: 1119px) {
			width: 100%;
		}
	}

	& h1 {
		margin-top: 0;

		& .subtitle {
			font-weight: 300;
		}
	}

	& .title {
		display: flex;
		flex-wrap: wrap;
		white-space: break-spaces;

		& .subtitle {
			font-weight: 300;
		}
	}

	& .title-logo {
		height: 78px;
	}

	& p {
		color: var(--gray-2);
		margin-bottom: 40px;
		margin-top: 12px;
		padding-right: 40px;
	}

	&.hashicorp {
		background: var(--black);
		background-image: url(/img/backgrounds/hashicorp.jpg);
	}

	&.terraform {
		background: var(--terraform);
		background-image: url(/img/backgrounds/bg-terraform.jpg);
	}

	&.vault {
		background: var(--vault);
		background-image: url(/img/backgrounds/bg-vault.jpg);
	}

	&.vagrant {
		background: var(--vagrant);
		background-image: url(/img/backgrounds/bg-vagrant.jpg);
	}

	&.nomad {
		background: var(--nomad);
		background-image: url(/img/backgrounds/bg-nomad.jpg);
	}

	&.consul {
		background: var(--consul);
		background-image: url(/img/backgrounds/bg-consul.jpg);
	}

	&.packer {
		background: var(--packer);
		background-image: url(/img/backgrounds/bg-packer.jpg);
	}

	&.boundary {
		background: var(--boundary);
		background-image: url(/img/backgrounds/bg-boundary.jpg);
	}

	&.waypoint {
		background: var(--waypoint);
		background-image: url(/img/backgrounds/bg-waypoint.jpg);
	}

	&.theme {
		background-position: bottom right;
		background-repeat: no-repeat;
		background-size: cover;
		color: var(--white);

		& p {
			color: var(--gray-5);
		}
	}
}
