.g-basic-table {
	& ul {
		border-top: 1px solid var(--gray-5);
		list-style: none;
		margin: 0;
		padding: 0;

		& li {
			border-bottom: 1px solid var(--gray-5);
			display: flex;
			padding: 16px 0;

			& span {
				margin: 0 12px;

				&:first-child {
					flex-shrink: 0;
					font-weight: var(--font-weight-bold);
					margin-right: 0;
					width: 100px;
				}

				@media (--medium-up) {
					margin: 0 36px;

					&:first-child {
						width: 150px;
					}
				}
			}
		}
	}
}
