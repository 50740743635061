/*
 * Hide components and sections that have frequently changing content from PercyThis reduces noise in our diffs
 * See https://docs.percy.io/docs/percy-specific-css#section-hiding-regions-with-percy-specific-css
 */
@media only percy {
	/* Keep size of element in layout to avoid layout shift diffs, but hide elements from view.
  This is useful for elements / components that don't change height */
	[data-percy='hidden'] {
		visibility: hidden !important;
	}

	/* Hide from DOM completely with display: none so we avoid any "page shifting" diffs */

	/* For elements / components that change height due to content changes */
	[data-percy='collapse'] {
		display: none !important;
	}
}
