.p-customer-reference .technology-stack {
	& dt {
		clear: left;
		float: left;
		font-weight: 700;
	}

	& dd {
		margin-left: 0;
		padding-bottom: 1em;

		& a {
			color: var(--brand-link-on-dark);
		}
	}
}
