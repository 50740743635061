/**** This file and its import are necessary for using the light theme as a base style with tippy.js/react *****/

.tippy-popper {
	pointer-events: all;

	& .tippy-tooltip.light-theme {
		& .tippy-arrow {
			border-bottom-color: #fff;
			border-top-color: #fff;
		}
	}
}

.tippy-tooltip {
	background: #191a1c;
	border-radius: 0 !important;
	color: #fff;
	font-size: 0.9em;
}

.tippy-popper[x-placement^='top'] .tippy-arrow {
	border-top-color: #191a1c;
}

.tippy-popper[x-placement^='bottom'] .tippy-arrow {
	border-bottom-color: #191a1c;
}
