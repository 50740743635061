#p-template-page {
	padding-top: 0;

	/* @TODO - Temporary patch of regression to table html. Consider long term fix: https://app.asana.com/0/1200197358790575/1201515470110676/f */
	& table {
		border-collapse: collapse;
		border-spacing: 0;
		border: 1px solid #ddd;
		border-right: 0;
		background-color: transparent;
		max-width: fit-content;
		margin-bottom: 20px;
		overflow-x: auto;
		display: block;

		& th {
			text-align: left;
		}

		& td,
		& th {
			border-right: 1px solid #ddd;
			padding: 0;
		}

		& > tbody > tr:nth-of-type(odd) {
			background-color: #f9f9f9;
		}

		& > thead > tr > th,
		& > tbody > tr > th,
		& > tfoot > tr > th,
		& > thead > tr > td,
		& > tbody > tr > td,
		& > tfoot > tr > td {
			padding: 8px;
			line-height: 1.42857143;
			vertical-align: top;
			border-top: 1px solid #ddd;
		}

		& > thead > tr > th {
			vertical-align: bottom;
			border-bottom: 2px solid #ddd;
		}

		& > caption + thead > tr:first-child > th,
		& > colgroup + thead > tr:first-child > th,
		& > thead:first-child > tr:first-child > th,
		& > caption + thead > tr:first-child > td,
		& > colgroup + thead > tr:first-child > td,
		& > thead:first-child > tr:first-child > td {
			border-top: 0;
			border-right: 1px solid #ddd;
		}

		& > tbody + tbody {
			border-top: 2px solid #ddd;
		}

		& table {
			background-color: #fff;
		}

		& tr.warning td {
			background: #fcf8e3;
		}
	}

	& .g-hero > picture > img {
		width: 100%;
	}

	& .content-wrapper {
		display: flex;
		margin-bottom: 128px;

		& .toc-sidebar {
			display: none;

			@media (--medium-up) {
				display: block;
				flex-shrink: 0;
				margin: 30px 0 0 60px;
				padding: 92px 0 0 0;
				width: 250px;
			}

			& .g-jump-nav.sticky {
				padding-top: 30px;
			}
		}

		& .content {
			margin: 72px 0;
			width: 100%;

			& .major-headline {
				&:not(:first-of-type) {
					margin-top: 128px;
				}
			}

			& a {
				word-break: break-word;
			}

			& hr {
				margin: 48px 0;
			}

			& p > code {
				background-color: #f9f2f4;
				color: #c7254e;
			}

			& .product-link {
				color: inherit;
				font-weight: var(--font-weight-bold);

				&::after {
					content: '\203A';
					font-size: 1.8rem;
					padding-left: 1rem;
				}
			}

			& blockquote {
				border-left: 8px solid var(--brand);
				font-size: 1.5em;
				margin-left: 0;
				padding-left: 2em;
			}

			& img {
				max-width: 100%;
				height: auto;
			}

			/* Overrides */

			& figure {
				/* Remove this reset once a global CSS Reset is in place: https://app.asana.com/0/1100423001970639/1144922207238928/f */

				/* Debt removal task: https://app.asana.com/0/1100423001970639/1151154741194254/f */
				margin: 0;

				&.full-width {
					& img {
						width: 100%;
					}
				}
			}
		}
	}

	& .g-section-header {
		margin: 0 auto;
		max-width: 784px;
		text-align: center;

		& h2 {
			margin: 0;
		}
	}

	/* Specific Page Overrides */
	& #template-page-tao-of-hashicorp {
		& section.text-image {
			border-bottom: 1px solid var(--gray-5);
			padding-bottom: 100px;
			padding-top: 100px;

			@media (max-width: 750px) {
				padding-bottom: 65px;
				padding-top: 65px;
			}

			&:first-child {
				padding-top: 0;
			}
			&:last-child {
				border-bottom: none;
				padding-bottom: 0;
			}
		}

		& .g-text-and-image {
			& .text {
				width: 70%;
				color: var(--gray-2);

				& h2 {
					color: var(--black);
				}

				@media (max-width: 750px) {
					width: 100%;
				}
			}

			& .imgwrap {
				max-height: 200px;
				width: 30%;

				@media (max-width: 750px) {
					max-height: none;
					width: 100%;
				}

				& picture {
					text-align: center;
					width: 100%;
				}

				& img {
					margin: 0;
					width: 70%;
				}
			}
		}
	}
}
