.c-featured-blog-post {
	background: var(--gray-6);
	display: flex;

	&.legacy {
		& .image-container {
			align-items: center;
			display: flex;
			justify-content: center;

			@media (max-width: 768px) {
				min-height: 250px;
			}

			& img {
				object-fit: contain;
				width: 75%;
			}
		}
	}

	&:hover {
		cursor: pointer;
		& img {
			transform: scale(1.04);
		}
	}

	@media (max-width: 768px) {
		flex-direction: column-reverse;
		flex-wrap: wrap;
	}

	& > * {
		width: 50%;

		@media (max-width: 768px) {
			width: 100%;
		}
	}

	& .image-container {
		overflow: hidden;
		& img {
			height: 100%;
			object-fit: cover;
			transition: 0.4s transform ease;
			width: 100%;
		}
	}

	& .blog-container {
		align-content: flex-start;
		color: var(--black);
		display: flex;
		flex-wrap: wrap;
		padding: 50px 40px;
		position: relative;

		&::before {
			box-shadow: 0 0 7.6px rgba(0, 0, 0, 0.038),
				0 0 20.9px rgba(0, 0, 0, 0.062), 0 0 50.3px rgba(0, 0, 0, 0.077),
				0 0 0 rgba(0, 0, 0, 0.12);
			content: '';
			height: 80%;
			left: 11px;
			position: absolute;
			top: 20px;
			width: 80%;
			z-index: -1;
		}

		& label {
			/* Must override the cursor supplied by the label */
			cursor: pointer;
			margin-bottom: 3px;
		}

		& h3 {
			margin-bottom: 24px;
			margin-top: 0;
		}

		& p {
			color: var(--gray-2);
			margin: 0;
			max-width: 480px;
		}
	}
}
