/* SPACING RULES */

/*

Note on spacing rules:

Medium spacing settings are declared first, without queries.
These are handled properly by PostCSS, and provide a graceful fallback
for browsers that don't support custom properties
(which aren't many: IE11, Blackberry, Opera Mini, QQ, Baidu)

Large and small spacing settings work where custom properties are supported.
That is, all browsers except IE11, Opera Mini, and QQ Browser.

For detailed spacing specs from the design team, see this Figma file:
https://www.figma.com/file/nvwWoCUzdY34qBXt7L9MWs/01-Visual-Guidelines-v2.0?node-id=6350%3A15550

*/

/* Medium */
:root {
	--space-grid-gap: 24px;
	--space-card-padding: 24px;
	--space-independent-content: 64px;
	--space-block-padding: 88px;
	--space-section1-intro: 24px;
	--space-display1-intro: 24px;
}

/* Element spacing rules */

/*

This is a prototype approach. The alternative is including margin rules within
the css for each page. The tradeoff is clarity about where the styling is coming
from, vs repeated lines of code.

*/

.page-section {
	padding-bottom: var(--space-block-padding);
	padding-top: var(--space-block-padding);
}

.g-type-body-large + .g-independent-content {
	margin-top: 64px;

	@media (--medium) {
		margin-top: 72px;
	}

	@media (--large) {
		margin-top: 80px;
	}
}

.g-button-group + .g-independent-content {
	margin-top: 32px;

	@media (--medium) {
		margin-top: 64px;
	}

	@media (--large) {
		margin-top: 72px;
	}
}

.g-independent-content + .g-button-group,
.g-independent-content + .hds-button-set {
	margin-top: 32px;

	@media (--medium) {
		margin-top: 64px;
	}

	@media (--large) {
		margin-top: 72px;
	}
}
