/* =========================
/* Global Component Styles
/* ========================= */
@import '@web/global-styles/src/style.css';

/* TODO: remove need for global-heading styles by using g-type classes
 task: https://app.asana.com/0/1100423001970639/1200632621534164/f */
@import '../styles/_temporary-global-headings.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/dist/tippy.css';
@import '../styles/tooltip.css';
@import '../styles/spacing.css';
@import '../styles/material.css';
@import '../styles/g-layout-content-sidebar.css';
@import '../styles/percy.css';
@import '../styles/keyboard-focus.css';

/* ========================
/* Local component styles
/* ======================== */

/* Customer Reference page component styles */
@import '../app/[locale]/(site)/case-studies/[casestudy]/components/case-study-content/style.css';
@import '../app/[locale]/(site)/case-studies/[casestudy]/components/customer-background/style.css';
@import '../app/[locale]/(site)/case-studies/[casestudy]/components/icon-list/style.css';
@import '../app/[locale]/(site)/case-studies/[casestudy]/components/partner-list/style.css';
@import '../app/[locale]/(site)/case-studies/[casestudy]/components/summary-list/style.css';
@import '../app/[locale]/(site)/case-studies/[casestudy]/components/technology-stack/style.css';

/* Resource page component styles (Legacy) */
@import '../app/[locale]/(site)/(en-only)/resources/components/single-resource-content/legacy-content/style.css';

/* Sentinel page component styles */
@import '../components/_page-components/sentinel/TemporaryCallouts/style.css';

/* @TODO - Temporary, Single Use component styles moved to their _page-components */
@import '../components/_page-components/sentinel/CodeSwitcher/style.css';

/* Site wide component styles */
@import '../components/basic-table/style.css';
@import '../components/branded-icon-wrapper/style.css';
@import '../components/demo-request/style.css';
@import '../components/jump-nav/style.css';
@import '../components/ModularContent/style.css';
@import '../components/ModularContent/MiniCallouts/style.css';
@import '../components/ModularContent/PersonList/style.css';
@import '../components/ModularContent/Packages/style.css';
@import '../components/ModularContent/SpeakersSection/style.css';
@import '../components/ModularContent/TextAndImage/style.css';

@import '../components/SalesForm/style.css';

/* ========================
/* Layout Styles
/* ======================== */
@import '../layouts/basic/components/basic-nav/style.css';

/* ========================
/* Page Styles
/* ======================== */
@import '../pages/[locale]/about/style.css';
@import '../pages/[locale]/contact/style.css';
@import '../pages/[locale]/go/[form]/style.css';
@import '../pages/[locale]/partners/cloud/[company]/style.css';
@import '../pages/[locale]/our-principles/style.css';
@import '../pages/[locale]/sentinel/style.css';
@import '../pages/[locale]/subscription-confirmation/style.css';
@import '../pages/[locale]/template-page/[templatepage]/style.css';
@import '../pages/[locale]/thank-you/[formslug]/style.css';

/* Blog Styles */
@import '../pages/[locale]/blog/style.css';
@import '../components/_page-components/blog/blog-hero/style.css';
@import '../components/_page-components/blog/featured-blog-post/style.css';
@import '../components/_page-components/blog/highlights-block/style.css';

:root {
	/* ILM/SLM */
	--text-gradient-infrastructure-cloud: linear-gradient(
		78deg,
		#b0ffff 1.19%,
		#8fd0ff 16.59%,
		#ddbfff 33.43%,
		#ffaed0 57.72%,
		#ffbeaf 72.62%,
		#fff8c9 86.08%,
		#fff 99.13%
	);
	--text-gradient-infrastructure-lifecycle-management: linear-gradient(
		258deg,
		#fff 0%,
		#b0ffff 32%,
		#8fd0ff 66.8%,
		#c796ff 100%
	);
	--text-gradient-security-lifecycle-management: linear-gradient(
		249deg,
		#fff 3.17%,
		#fff4ac 36.03%,
		#ffac99 71.73%,
		#ff89a1 99.04%
	);
	--gradient-infrastructure-lifecycle-management: #cfacf8, #ae46e6, #6e7bce,
		#6ec2ee, #9ce7f5;
	--gradient-security-lifecycle-management: #fff8c5, #ffa283, #ff70a3;
	--heading-gradient-infrastructure-lifecycle-management: linear-gradient(
		80.26deg,
		#7d48ce 1.81%,
		#6c6ae3 28.86%,
		#6181f0 56.4%,
		#5db1fa 82.5%,
		#c5ebfe 98.44%
	);
	--heading-gradient-security-lifecycle-management: linear-gradient(
		71deg,
		#fff0d6 0.51%,
		#ffba74 14.93%,
		#ff9266 53.74%,
		#ff6562 76.62%,
		#ff4774 100%
	);
	--products-gradient-infrastructure-lifecycle-management: linear-gradient(
		90deg,
		#a067da 0%,
		#6d69e2 17.5%,
		#32b8f2 38%,
		#4bd199 57.5%,
		#46b4bc 79%,
		#addee2 100%
	);
	--products-gradient-security-lifecycle-management: linear-gradient(
		90deg,
		#fff8c5 0%,
		#ffa283 37.58%,
		#ff70a3 100%
	);
	--icon-gradient-infrastructure-cloud: linear-gradient(
		80.71deg,
		#b0ffff -5.86%,
		#8fd0ff 13.94%,
		#ddbfff 35.6%,
		#ffaed0 66.85%,
		#ffbeaf 86.02%,
		#fff8c9 103.33%,
		#fff 120.12%
	);
	--icon-gradient-infrastructure-lifecycle-management: linear-gradient(
		266.74deg,
		#fff -54.9%,
		#b0ffff -5.33%,
		#8fd0ff 48.57%,
		#c796ff 100%
	);
	--icon-gradient-security-lifecycle-management: linear-gradient(
		248.33deg,
		#fff -29.69%,
		#fff4ac 18.2%,
		#ffac99 70.24%,
		#ff89a1 110.05%
	);
}
