#p-about {
	& .g-section-header {
		margin-bottom: 40px;

		& div[data-testid='description'] {
			color: var(--gray-5);
		}
	}

	& #intro {
		background: black;
		background: center / cover no-repeat
			url('/img/backgrounds/about-hero-bg.jpg');
		color: white;
	}

	& #who-we-are {
		background: black;
		color: white;
	}

	& #tao-cta {
		color: white;
		position: relative;
		text-align: center;
		& div[data-testid='description'] {
			color: var(--white);
		}
	}

	& #leadership {
		& .person-list {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			list-style: none;
			margin: 0 auto;
			padding: 0;

			& li {
				margin-bottom: 70px;
				margin-right: 13.33333333%;
				width: 15%;

				&:nth-child(4n) {
					margin-right: 0;
				}

				&:last-child {
					margin-right: 0;
				}

				@media (max-width: 768px) {
					margin-right: 20%;
					width: 40%;

					&:nth-child(2n) {
						margin-right: 0;
					}
				}

				@media (max-width: 350px) {
					margin-right: 0;
					width: 100%;
				}
				& .person-image {
					& picture {
						display: block;
						width: 100%;

						& img {
							display: block;
							width: 100%;
						}
					}
				}

				& .person-info {
					& > span {
						display: block;

						&.name {
							margin-top: 16px;

							@media (--medium-up) {
								margin-top: 24px;
							}
						}

						&.job {
							color: var(--gray-2);
							margin: 0;
							margin-top: 0.3rem;
						}
					}

					& .social-links {
						display: flex;
						list-style: none;
						margin-top: 1rem;
						padding: 0;

						& li {
							margin-right: 15px;

							& img {
								display: block;
							}
						}
					}
				}
			}
			&.top {
				& li {
					&:nth-child(3n) {
						margin-right: 0;
					}
				}
			}
		}
	}

	& #prefooter {
		color: white;
		text-align: center;
	}

	/* Utility Classes */

	& .gradient-bg {
		background: var(--brand);
		color: white;
		overflow: hidden;
		position: relative;
	}
}
