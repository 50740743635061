/*

Content and Sidebar Layout used for Blog and Resource _single views

Includes styles for:
- article.content-area
- ul.table-contents-sidebar
- aside.right-rail
- aside.narrow-right-rail

In NextJS land it may make sense to split out some components, eg the promo part,
and perhaps to make this content+sidebar layout into a component that accepts content to render

*/

.g-layout-content-sidebar {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	@media (min-width: 1024px) {
		flex-wrap: nowrap;
	}

	/* Main content area for Blog and Resource */

	/* Styles for inner content are in the corresponding _single.css files */
	& > article.content-area {
		max-width: 45em;
		min-width: 0;
		padding: 92px 0;
		width: 100%;
	}

	/*
   * .table-contents-sidebar
   * Not used by Blog view
   * This is used when the resource has a table of contents, such as
   * /resources/why-consider-terraform-enterprise-over-open-source
   * Also used in css/pages/_template-page.css.
   * @TODO consolidate styles for template page TOC sidebar,
   * (will be considerably easier once resources and templates can be previewed
   * in the same project, right now the split between www-server and hashicorp-www-middleman
   * makes it less fun)
   */
	& ul.table-contents-sidebar {
		display: none;

		@media (min-width: 1024px) {
			display: block;
			flex-shrink: 0;
			height: 100vh;
			list-style: none;
			margin: 0 64px 0 0;
			padding: 92px 0 0 0;
			width: 240px;

			/* TODO: repeated between this and css/pages/_template-page.css */
			& li {
				margin-bottom: 0.75em;
			}

			& a {
				color: inherit;

				&:hover,
				&.selected {
					font-weight: 600;
				}
			}

			@supports (position: sticky) {
				position: sticky;
				top: 0;
			}
		}
	}

	/*
   * .right-rail
   * On both Blog and Resources, this full right rail is used with a promo
   * /resources/consul-service-mesh-deep-dive
   */
	& > aside.right-rail {
		display: block;
		margin: 0 auto 88px auto;
		max-width: 480px;
		width: auto;

		@media (min-width: 1024px) {
			flex-shrink: 0;
			margin: 0 0 88px 78px;
			padding-top: 92px;
			width: 336px;
		}

		& hr {
			margin: 3rem 0;
		}

		& .promo {
			& img {
				max-width: 100%;
			}

			& > a {
				color: inherit;
				text-decoration: none;
			}

			& .content-wrap {
				& h3 {
					font-family: var(--font-display), sans-serif;
					font-size: 1.125em;
					margin: 10px 0;
				}

				& .link {
					color: var(--brand);
					display: block;
					font-size: 0.9rem;
					font-weight: 400;
					margin-top: 10px;
				}
			}
		}

		& .g-social-share-links {
			flex-direction: row;
			justify-content: center;
			margin-bottom: 50px;

			@media (min-width: 1024px) {
				justify-content: flex-start;
			}

			& li:not(:last-of-type) {
				margin: 0 30px 0 0;
			}
		}
	}

	/*
   * .narrow-right-rail
   * Not used by Blog view
   * Shows only the social icons, stacked vertically
   * This is used when the resource has a table of contents, such as
   * /resources/why-consider-terraform-enterprise-over-open-source
   */
	& aside.narrow-right-rail {
		flex-shrink: 0;
		margin: 72px 0;
		width: 100%;

		@media (min-width: 1024px) {
			margin: 0 0 0 64px;
			padding-top: 92px;
			width: 23px;
		}

		& .g-social-share-links {
			flex-direction: row;
			justify-content: center;

			@media (min-width: 1024px) {
				flex-direction: column;
			}

			& li:not(:last-of-type) {
				margin: 0 30px 0 0;

				@media (min-width: 1024px) {
					margin: 0 0 30px 0;
				}
			}
		}
	}
}
