.p-customer-reference .summary-list {
	background-color: var(--black);
	color: var(--white);

	& ul {
		list-style: none;
		margin: 0 auto;
		padding-bottom: var(--hdsplus-spacing-14);
		padding-top: var(--hdsplus-spacing-14);

		@media (--medium-up) {
			display: flex;
			flex-wrap: wrap;
		}

		& li {
			margin: 0;

			@media (--medium-up) {
				/* Each item takes up 1/3 of the available width on medium+ */
				width: calc(100% / 3);

				/* Each first item in a row of 3 items includes a gap on its right on medium+ screens */
				&:nth-child(3n + 1) {
					padding-right: var(--hdsplus-spacing-12);
				}

				/* Each last item in a row of 3 items includes a gap on its left on medium+ screens */
				&:nth-child(3n + 3) {
					padding-left: var(--hdsplus-spacing-12);
				}

				/* Each item after the first row includes a gap above it on medium+ screens */
				&:nth-child(1n + 4) {
					padding-top: var(--hdsplus-spacing-12);
				}
			}

			@media (--small) {
				/* Each item after the first includes a gap above it on small screens */
				&:nth-child(1n + 2) {
					padding-top: var(--hdsplus-spacing-10);
				}
			}

			& .icon {
				display: block;
				height: var(--hdsplus-spacing-07);
				width: var(--hdsplus-spacing-07);
			}

			& .content {
				padding-top: var(--hdsplus-spacing-05);

				& h4 {
					margin: 0;
				}

				& span {
					color: var(--gray-5);
					display: block;
					font-family: var(--font-body);
					font-weight: var(--font-weight-reg);
					margin: 0;
				}
			}
		}
	}

	& .citations {
		padding-bottom: var(--hdsplus-spacing-14);

		& .citation {
			color: var(--gray-2);
			font-size: 14px;
			margin: 0;
			text-align: right;

			& a {
				color: var(--gray-2);

				&:hover {
					color: var(--brand-link-on-dark);
				}
			}
		}
	}
}
