.g-basic-nav {
	align-items: center;
	background: black;
	display: flex;
	padding: 0 30px;

	& .logo {
		margin: 15px auto;

		& img {
			height: 38px;
			width: 38px;
		}
	}

	& .close-icon {
		cursor: pointer;
	}
}
