#p-principles {
	& section.hero {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 8rem 0 10rem;
		text-align: center;
		& p {
			color: var(--gray-2);
			margin-left: auto;
			margin-right: auto;
			max-width: 730px;
		}
	}

	& .g-jump-nav {
		margin: -60px 0 0 60px;
		padding-top: 60px;

		&.sticky {
			margin-left: 130px;
		}

		& ul {
			border-left: 2px solid var(--gray-5);
		}
	}

	& .content {
		display: flex;
		justify-content: space-between;
		margin-bottom: 32px;
		margin-top: 35px;

		@media (min-width: 768px) {
			margin-bottom: 160px;
			margin-top: 60px;
		}

		& article {
			color: var(--gray-2);
			margin-top: 12px;
			max-width: 848px;
			width: 100%;

			& h2 {
				color: var(--black);
				margin: 0 0 16px;
			}

			& section {
				margin-top: 32px;
				padding-top: 16px;

				@media (min-width: 768px) {
					margin-top: 48px;
				}

				@media (min-width: 1120px) {
					margin-top: 64px;
				}

				&:first-child {
					margin-top: 0;
					padding-top: 0;
				}
			}

			& .lead {
				font-size: 1.125rem;
				font-weight: var(--font-weight-light);
				line-height: 1.445;

				@media (min-width: 768px) {
					font-size: 1.25rem;
					line-height: 1.6;
				}
			}
		}
	}
}

/* -------------- NEW ----------------- */

.new-main-image-grid {
	height: 248px;
	position: relative;
	display: grid;
	gap: 0;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;

	@media (min-width: 480px) {
		grid-template-columns: 1.5fr 1fr;
		grid-column-gap: 12px;
	}

	@media (--medium-up) {
		width: 100%;
		margin-left: auto;
		grid-template-columns: 2.5fr 1.5fr 2.5fr;
	}

	@media (--large) {
		height: 360px;
	}
}

.main-next-image {
	position: relative;
	flex-grow: 1;
}

.new-image-grid-1,
.new-image-grid-2,
.new-image-grid-3 {
	display: grid;
	grid-template-rows: 1fr;
	margin-bottom: 24px;

	@media (--medium-up) {
		margin-bottom: 36px;
	}

	@media (--large) {
		margin-bottom: 48px;
	}
}

.new-image-grid-2,
.new-image-grid-3 {
	height: 216px;

	@media (--medium) {
		height: 242px;
	}

	@media (--large) {
		height: 260px;
	}
}

.new-image-grid-1 {
	grid-template-columns: 1fr;
	min-height: 248px;

	@media (--large) {
		height: 430px;
	}
}

.new-image-grid-2 {
	grid-template-columns: 1fr;

	@media (min-width: 480px) {
		grid-template-columns: 1.5fr 1fr;
		grid-column-gap: 12px;
		height: 242px;
	}

	@media (--large) {
		height: 260px;
	}

	&.reverseLayout {
		@media (min-width: 480px) {
			grid-template-columns: 1fr 1.5fr;
		}
	}
}

.new-image-grid-3 {
	min-height: 248px;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;

	@media (min-width: 480px) {
		grid-template-columns: 1fr 1.5fr;
		grid-column-gap: 12px;
	}

	@media (--large) {
		grid-template-columns: 2.5fr 1.5fr 2fr;
	}
}

.next-image {
	position: relative;
	height: 100%;
}
