#p-subscription-confirmation {
	& .show-reg .g-hero {
		padding: 72px 0 167px;
	}

	& .g-hero {
		border-top: 1px solid var(--gray-1);

		& h2 {
			text-align: center;
		}
	}

	& #registration,
	& #webinars {
		background-color: var(--gray-6);
	}

	& #registration {
		display: flex;
		justify-content: center;

		& > div {
			align-items: center;
			background-color: var(--white);
			border: solid 1px var(--gray-5);
			box-shadow: 0 16px 16px -12px rgba(32, 40, 61, 0.2);
			display: flex;
			justify-content: center;
			margin: 10px 8px 0;
			max-width: 845px;
			min-height: 300px;
			opacity: 0;
			padding: 24px;
			text-align: center;
			transition: all 0.6s;
			transition-timing-function: cubic-bezier(0.28, 0, 0.31, 1);
			width: 100%;
			z-index: 1;

			@media (min-width: 768px) {
				margin: -70px 93px 0;
				padding: 48px 60px;
			}

			@media (min-width: 1120px) {
				padding-left: 108px;
				padding-right: 108px;
			}

			&.visible {
				opacity: 1;
				transform: translateY(-50px);
			}

			& h4 {
				margin: 0 0 1rem;
			}
		}
	}

	& #webinars {
		padding: 48px 0;

		@media (min-width: 768px) {
			padding: 80px 0 120px;
		}

		& h2 {
			margin-top: 0;
		}

		& > .g-grid-container > div {
			@media (min-width: 768px) {
				margin-top: 64px;
			}
		}
	}

	& #resources {
		border-top: solid 1px var(--gray-5);
		padding-top: 15px;

		@media (min-width: 768px) {
			padding-bottom: 20px;
			padding-top: 80px;
		}
	}
}
