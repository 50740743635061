.p-customer-reference ul.icon-list {
	list-style: none;
	padding: var(--hdsplus-spacing-12) 0;

	/* Overly aggressive selectors in the case-study-content
   * forces us to do this there, unfortunately. That should be
   * addressed in the future if this component ever gets elevated. */
	padding-left: 0 !important;

	@media (--small) {
		padding: var(--hdsplus-spacing-10) 0;
	}

	&:only-child {
		padding-top: 0;
	}

	& h5 {
		margin: 0;
	}

	/* Need a class here to force specificity over the case-study-content */
	& li.icon-list-element {
		align-items: flex-start;
		display: flex;

		&:not(:last-child) {
			margin-bottom: var(--hdsplus-spacing-12);

			@media (--small) {
				margin-bottom: var(--hdsplus-spacing-10);
			}
		}
		& .bullet-icon {
			/* HACK: Restricts sizes of too-large icons. */
			max-width: 43px;
			min-width: 43px;
			height: auto;
		}
		& .bullet-point {
			margin-left: 25px;
			margin-top: 6px;
		}
	}
}
