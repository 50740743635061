.c-mini-callouts {
	background: black;
	color: white;
	overflow: hidden;

	& ul {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		list-style: none;
		margin: 0;
		padding: 20px 0;

		@media (max-width: 600px) {
			justify-content: center;
		}

		& li {
			align-items: center;
			display: flex;
			justify-content: center;
			width: 33.333333%;

			@media (max-width: 900px) {
				width: 50%;

				&:nth-child(n + 3) {
					margin-top: 20px;
				}
			}

			@media (max-width: 600px) {
				width: 100%;

				&:nth-child(n + 2) {
					margin-top: 20px;
				}
			}

			& img {
				margin-right: 20px;
				max-width: 90px;
			}

			& p {
				margin: 0;
			}

			& strong {
				font-size: 1.1rem;
				font-weight: var(--font-weight-bold);
			}
		}
	}
}
