#p-sentinel .temporary-callouts .callout-item {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	width: 100%;

	& .icon {
		margin-bottom: 16px;
	}

	& .heading {
		color: var(--white);
	}

	& .content {
		color: var(--gray-4);
		margin-top: 8px;
	}

	& .links {
		margin-top: 20px;
	}
}
