.p-customer-reference {
	& .customer-background-container {
		background-color: var(--black);
		padding: var(--hdsplus-spacing-14) 0;
	}

	& .customer-background {
		background-color: var(--brand);
		color: var(--brand-text-on-primary);
		margin-left: auto;
		margin-right: auto;
		max-width: 1312px;
		width: 100vw;

		@media (--small) {
			padding: 44px 0;
		}

		@media (--medium-up) {
			padding: var(--hdsplus-spacing-14) 0;
		}

		& .g-grid-container {
			@media (--medium-up) {
				display: flex;
			}
		}

		& header {
			@media (--medium-up) {
				align-items: center;
				display: flex;
				flex: 0 0 33%;
				flex-direction: column;
				padding-right: var(--hdsplus-spacing-08);
			}

			& h3.heading {
				@media (--small) {
					margin-bottom: var(--hdsplus-spacing-04);
					margin-top: 0;
				}
			}

			@media (--small) {
				margin-bottom: var(--hdsplus-spacing-08);
			}

			& img {
				max-width: 180px;
				height: auto;
			}
		}

		& .content {
			@media (--medium-up) {
				flex: 0 1 55%;
			}
		}
	}

	& .neutral .customer-background {
		background-color: var(--gray-2);
		color: var(--gray-5);
	}
}
