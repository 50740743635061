#p-sentinel {
	& .temporary-callouts {
		& h1,
		& h2,
		& h3 {
			margin: 0;
		}

		& p {
			margin: 0;
		}
	}
}
