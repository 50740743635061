#p-contact {
	margin-bottom: 64px;

	@media (--medium-up) {
		margin-bottom: 88px;
	}

	& .g-section-header {
		margin-bottom: 100px;
		margin-top: 48px;
	}

	& #blocks {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		margin: 32px auto 0 auto;
		max-width: 850px;
		padding: 0;

		@media (max-width: 500px) {
			flex-direction: column;
		}

		& li {
			border: 2px solid transparent;
			box-shadow: inset 0 0 1px var(--gray-2);
			margin-bottom: 32px;
			margin-right: 32px;
			text-align: center;
			transition: 0.25s ease;
			transition-property: border, box-shadow, transform;
			width: calc(33.33333333% - 21.33333333px);

			&:nth-child(3n) {
				margin-right: 0;
			}

			@media (max-width: 720px) {
				width: calc(50% - 16px);
				&:nth-child(3n) {
					margin-right: 32px;
				}

				&:nth-child(2n) {
					margin-right: 0;
				}
			}

			@media (min-width: 501px) {
				&:nth-child(3n + 1):last-child {
					margin-right: 0;
					width: 100%;
				}
			}

			@media (max-width: 500px) {
				margin-right: 0;
				width: 100%;
			}

			&:hover {
				border: 2px solid var(--brand);
				box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
				transform: translateY(-4px);
			}

			& a {
				align-items: center;
				color: var(--black);
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 48px 32px;
			}

			& img {
				background-position: center center;
				background-repeat: no-repeat;
				background-size: 100% 100%;
				height: 48px;
				width: 48px;
			}

			& h5 {
				margin: 1em 0;
			}

			& p {
				align-self: stretch;
				margin: 0;
			}

			& .contact-icon {
				background-image: url('/img/icons/contact-icon.svg');
			}
			& .support-icon {
				background-image: url('/img/icons/support-icon.svg');
			}
			& .partner-icon {
				background-image: url('/img/icons/partners-icon.svg');
			}
			& .jobs-icon {
				background-image: url('/img/icons/jobs-icon.svg');
			}
			& .media-icon {
				background-image: url('/img/icons/media-icon.svg');
			}
		}
	}
}
