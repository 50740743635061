@import './_temp-reset.css';
@import '../partials/CalloutItem/style.css';

#p-sentinel .temporary-callouts {
	background: var(--black);
	padding-bottom: 64px;
	padding-top: 64px;

	@media (--medium) {
		padding-bottom: 88px;
		padding-top: 88px;
	}

	@media (--large) {
		padding-bottom: 128px;
		padding-top: 128px;
	}

	& > .g-grid-container {
		& > .headings {
			margin-bottom: 48px;
			text-align: left;

			@media (--medium) {
				margin-bottom: 56px;
			}

			@media (--large) {
				margin-bottom: 64px;
			}

			& > h2 {
				color: var(--white);
				margin-left: auto;
				margin-right: auto;
				max-width: 38em;
				text-align: center;
			}

			& > div {
				margin: 0;

				& > p {
					color: var(--gray-4);
					margin-left: auto;
					margin-right: auto;
					margin-top: 16px;
					max-width: 38em;
					text-align: center;

					@media (--large) {
						margin-top: 24px;
					}
				}
			}
		}

		& > .items {
			display: flex;
			flex-wrap: wrap;
			margin: -24px;
			position: relative;

			@media (--medium) {
				margin: -20px;
			}

			@media (--large) {
				margin: -32px;
			}

			& .callout-item-wrapper {
				display: flex;
				padding: 24px;
				width: 100%;

				@media (--medium-up) {
					padding: 20px;
					width: 33.33%;
				}

				@media (--large) {
					padding: 32px;
				}
			}
		}
	}
}
