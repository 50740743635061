/*

This CSS file is used only by /community
Design token work that is progress will replace this file

https://app.asana.com/0/1131481323011561/1136952429230922/f

*/

:root {
	--shadow-level-0: none;
	--shadow-level-1: 0 8px 12px rgba(37, 38, 45, 0.08);
	--shadow-level-2: 0 16px 28px rgba(37, 38, 45, 0.12);
	--shadow-level-3: 0 24px 25px rgba(37, 38, 45, 0.16);
	--material-border-radius: 3px;
	--material-border: 1px solid var(--gray-5);
	--material-hover-transform: translateY(-4px);
	--material-transition: transform 0.25s, box-shadow 0.25s;
}
