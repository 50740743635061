section.video-container {
	margin: 50px 0;
	padding-bottom: 56.25%;
	position: relative;

	& iframe,
	& object,
	& embed {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

.modular-content-image-section {
	/* Targets the ReactImage component */
	& :first-child {
		display: grid;
	}

	&.left img {
		justify-self: start
	}
			
	&.center img {
		justify-self: center
	}

	&.right img {
		justify-self: end
	}
}

.modular-content-image-caption {
	padding-top: var(--wpl-spacing-03);
}