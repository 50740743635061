.p-customer-reference .case-study-content {
	color: var(--token-color-foreground-primary);

	@media (--small) {
		padding: var(--hdsplus-spacing-10) 0;
	}

	@media (--medium-up) {
		padding: var(--hdsplus-spacing-16) 0;
	}

	&.dark {
		background-color: var(--black);

		& + .case-study-content.dark {
			padding-top: 0;
		}
	}

	&.light {
		& + .case-study-content.light {
			padding-top: 0;
		}

		& .g-grid-container {
			/** on lightbackground, the small quote needs to be black to retain contrast */
			& span {
				color: var(--black);
			}
		}
	}

	& .g-grid-container {
		@media (--large) {
			display: flex;
		}

		& .g-type-long-body {
			@media (--large) {
				padding-right: 144px;
			}
		}

		& > header {
			@media (--large) {
				padding-right: 80px;
				width: calc(100% / 3);
			}

			@media (max-width: 1119px) {
				padding-bottom: var(--hdsplus-spacing-08);
			}

			& h3 {
				margin: 0;

				@media (max-width: 1119px) {
					padding-bottom: var(--hdsplus-spacing-08);
				}

				& + span svg {
					@media (--large) {
						margin-top: var(--hdsplus-spacing-16);
					}
				}
			}

			& span {
				color: var(--brand);
			}

			& svg {
				margin-bottom: var(--hdsplus-spacing-07);
				max-height: 30px;
				max-width: 36px;

				@media (--large) {
					margin-top: var(--hdsplus-spacing-08);
				}
			}

			& span.no-heading {
				& svg {
					@media (--large) {
						margin-top: 0;
					}
				}
			}

			& blockquote,
			& .attribution {
				font-weight: var(--font-weight-reg);
				margin: 0;

				@media (max-width: 1119px) {
					width: calc(100% / 3 * 2);
				}

				@media (--small) {
					width: 100%;
				}
			}

			& .attribution {
				color: var(--gray-3);
				font-weight: var(--font-weight-medium);
				margin: 2em 0 1em 0;
			}
		}

		& > div {
			@media (--medium-up) {
				width: calc(100% / 3 * 2);
			}

			& dl,
			& h4,
			& li,
			& p,
			& ul {
				margin: 0;

				&:nth-child(1n + 2) {
					@media (--small) {
						margin-top: 1em;
					}

					@media (--medium-up) {
						margin-top: 1em;
					}
				}
			}

			& ul {
				padding-left: 1em;
			}

			& h4:nth-child(1n + 2) {
				margin-top: var(--hdsplus-spacing-14);

				@media (--small) {
					margin-top: var(--hdsplus-spacing-10);
				}

				& + p {
					margin-top: 0.25em;
				}
			}
		}
	}
}
