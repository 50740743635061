.g-boolean-input {
	align-items: flex-start;
	display: flex;
	flex-direction: row;
	margin: 12px 0;

	& label {
		color: var(--gray-2);
		cursor: pointer;
		font-size: 0.95em;
	}

	& input[type='checkbox'] {
		appearance: none;
		background: #fff;
		border-radius: 2px;
		cursor: pointer;
		flex-shrink: 0;
		height: 20px;
		margin-left: 0;
		margin-right: 12px;
		transition: border 0.2s;
		width: 20px;

		&:hover {
			border: 1px solid var(--brand);
		}

		&:focus {
			border: 1px solid var(--brand);
			outline: 0;
		}

		&:checked {
			background: url('/img/icons/checkbox-check.svg') no-repeat center center;
			background-color: var(--brand);
			border-color: var(--brand);
		}
	}

	&.on-dark {
		& label {
			color: var(--gray-5);
		}

		& input[type='checkbox'] {
			background-color: var(--gray-1);
			border: 1px solid var(--gray-2);

			&:hover {
				border: 1px solid var(--brand);
			}

			&:focus {
				border: 1px solid var(--brand);
				outline: 0;
			}

			&:checked {
				background: url('/img/icons/checkbox-check.svg') no-repeat center center;
				background-color: var(--brand);
				border-color: var(--brand);
			}
		}
	}
}
