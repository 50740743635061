.branded-icon-wrapper {
	align-items: center;
	background: var(--brand);
	border-radius: 5px;
	display: flex;
	height: 56px;
	justify-content: center;
	width: 56px;

	& svg {
		& [stroke] {
			stroke: var(--white);
		}
		& [fill] {
			fill: var(--white);
		}
	}

	&.brand-terraform {
		background: var(--terraform);
	}
	&.brand-consul {
		background: var(--consul);
	}
	&.brand-nomad {
		background: var(--nomad);
	}
	&.brand-packer {
		background: var(--packer);
	}
}
