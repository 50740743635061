.g-text-and-image {
	align-items: center;
	display: flex;

	@media (max-width: 750px) {
		flex-direction: column-reverse;
	}

	&.reverse {
		@media (max-width: 750px) {
			flex-direction: column;
		}
	}

	& .imgwrap {
		align-items: center;
		display: flex;
		justify-content: center;
		width: 50%;

		@media (max-width: 750px) {
			flex-shrink: 0;
			margin-bottom: 48px;
			width: 100%;
		}

		& .img {
			display: block;
		}

		& img {
			max-width: 100%;
		}
	}

	& .text {
		padding-right: 25px;
		width: 50%;

		/* make sure text is properly centered even when there is a headline */
		& *:first-child {
			margin-top: 0;
		}

		@media (max-width: 750px) {
			padding-right: 0;
			width: 100%;
		}
	}
}
