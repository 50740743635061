#p-cloud-partner-page {
	& .header-section {
		margin-top: 3rem;

		& .intro {
			display: flex;
			justify-content: space-between;
			padding-bottom: 80px;

			@media (max-width: 650px) {
				display: block;
			}
		}

		& .logo-container {
			align-items: center;
			border: 1px solid var(--gray-5);
			display: flex;
			height: 170px;
			justify-content: center;
			margin: 0;
			max-width: 300px;
			min-width: 220px;
			padding: 16px 0;
			width: 33%;

			@media (max-width: 650px) {
				margin: 0 auto 48px;
				width: 100%;
			}

			& img {
				max-height: 75%;
				width: 50%;
			}
		}

		& .text-container {
			flex-grow: 1;
			margin-left: 4%;
			width: 62%;

			@media (max-width: 650px) {
				margin-left: auto;
				width: 100%;
			}
		}

		& h5 {
			color: var(--gray-2);
			margin: 0;
		}

		& h1 {
			margin-bottom: 14px;
			margin-top: 14px;
		}

		& .website-link {
			color: var(--black);

			&::after {
				background: url('/img/icons/chevron-right.svg') 0 0 no-repeat;
				background-size: contain;
				content: '';
				display: inline-block;
				height: 12px;
				margin-left: 10px;
				margin-top: 1px;
				width: 12px;
			}

			&:hover {
				text-decoration: underline;
			}
		}
	}

	& .footer-section {
		background: var(--brand);
		color: var(--white);
		overflow: hidden;
		padding-bottom: 160px;
		padding-top: 128px;
		text-align: center;

		@media (max-width: 1119px) {
			padding-bottom: 104px;
			padding-top: 78px;
		}

		@media (max-width: 767px) {
			padding-bottom: 80px;
			padding-top: 56px;
		}

		& .g-grid-container {
			align-items: center;
			display: flex;
			flex-direction: column;
		}

		& h1 {
			margin: 0;
		}

		& p {
			margin: 24px 0 0 0;
			max-width: 730px;

			@media (max-width: 1119px) {
				max-width: 534px;
			}

			@media (max-width: 767px) {
				margin-top: 16px;
			}
		}

		& .buttons {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin: 24px -8px 0;

			@media (max-width: 767px) {
				margin-top: 16px;
			}

			& .g-btn {
				margin: 16px 8px 0;
			}
		}
	}
}
