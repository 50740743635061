.p-customer-reference .partner-list {
	list-style: none;
	margin: 0;
	padding: 0;

	/* There's some overly aggressive CSS selectors in the case-study-content
   * component that need to be addressed, so this doesn't need to be done */
	padding-left: 0 !important;

	& > li {
		display: flex;
		flex-direction: column;
		width: 100%;

		@media (--large) {
			flex-direction: row;
		}

		&:not(:last-child) {
			padding-bottom: var(--hdsplus-spacing-12);

			@media (--small) {
				padding-bottom: var(--hdsplus-spacing-10);
			}
		}

		& .bio {
			/* There's some overly aggressive CSS selectors in the case-study-content
       * component that need to be addressed, so this doesn't need to be done */
			margin-top: 0 !important;
		}

		& .details {
			margin-bottom: var(--hdsplus-spacing-04);
			margin-right: var(--hdsplus-spacing-08);

			& p {
				& span {
					display: block;
				}
			}

			& img {
				max-height: 176px;
				max-width: 176px;
				height: auto;
				width: auto;
			}
		}
	}
}
